import 'underscore';
import './template/jquery.validate.min.js';
import './template/additional-methods.min.js';
import './template/utility.js';
import './template/demo.js';
import './template/main.js';
import './template/summernote.min';

$(document).ready(function(){
    "use strict";
    window.Core.init();
    window.Demo.init();
});

console.log('app footer js');